
.main-div{
  overflow-x: hidden;
  background-color: #88301E;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.main-div_2{
  overflow-x: hidden;
  background-color: #88301E;
  /* padding: 1rem 0 1rem 3rem; */
  padding-bottom: 3rem;
}
article {
  display: flex;
  width: 200% 100%;
  animation: bannermove 20s linear infinite;
}
.article_two{
  display: flex;
  width: 200% 100%;
  animation: bannermoveTwo 20s reverse linear infinite;
}
article.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}z
div {
  width: 100%;
}
ul {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
li {
  width: 100%;
  background: transparent;
  margin: 0 1rem;
}
img {
  width: 250px;
  height: 280px;
  /* border: 2px solid gray; */
  border-radius: 10px;
}
@keyframes bannermove {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}
/* ----------------------------------- */
@keyframes bannermoveTwo {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}