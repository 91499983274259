
.new {
  background-image: url('./aaa.png') !important; 
background-size: cover;
background-repeat: no-repeat;
/* width: 100%; */
background-origin: center;
background-attachment: fixed;
}
.newbg {
  background-image: url('./Normandy.png') !important; 
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 100%; */
  background-origin: center;
  background-attachment: fixed;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.ok {
  background-color: #88301E;
  color: #fff;
}
.roadmap-root {
  background-image: url("./Newsstand.jpg");
  background-position: center;
  background-attachment:fixed;
  background-repeat: no-repeat;
  background-size: 100% 100% ;
}

@media screen and (max-width:700px) {
  .secThreeTextParas,.popup{
      width: 100vw !important;
  }
  .secThreeTextParas{
    margin: 10px 0px !important;
  }
}

/* .App {
  position: relative;
} */

.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: rgb(82, 2, 2);
  border: none;
  font-size: 30px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.scroll-button:hover {
  opacity: 1;
}
