@import url(https://fonts.cdnfonts.com/css/ocr-a-extended);

@font-face {
    font-family: "NewGardinerSMP";
    src:
     url(/static/media/NewGardinerSMP.e7b75a1f.ttf) format('truetype');
    font-weight: bold;

}
@font-face {
    font-family: "Ink Free";
    src: 
     url(/static/media/Inkfree.0f15d867.ttf) format("truetype");
    font-weight: bold;
    } 


.new {
  background-image: url(/static/media/aaa.a17832fa.png) !important; 
background-size: cover;
background-repeat: no-repeat;
/* width: 100%; */
background-origin: center;
background-attachment: fixed;
}
.newbg {
  background-image: url(/static/media/Normandy.60a5299f.png) !important; 
  background-size: cover;
  background-repeat: no-repeat;
  /* width: 100%; */
  background-origin: center;
  background-attachment: fixed;
}

#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

.ok {
  background-color: #88301E;
  color: #fff;
}
.roadmap-root {
  background-image: url(/static/media/Newsstand.ca12dc61.jpg);
  background-position: center;
  background-attachment:fixed;
  background-repeat: no-repeat;
  background-size: 100% 100% ;
}

@media screen and (max-width:700px) {
  .secThreeTextParas,.popup{
      width: 100vw !important;
  }
  .secThreeTextParas{
    margin: 10px 0px !important;
  }
}

/* .App {
  position: relative;
} */

.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: rgb(82, 2, 2);
  border: none;
  font-size: 30px;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
  z-index: 9999;
}

.scroll-button:hover {
  opacity: 1;
}

/* @media screen and (max-width:320px) {
    .tutor_main{
        margin-top: 52rem !important;
    }

    
    
}

@media (min-width:321px) and (max-width:360px) {
    .tutor_main{
        margin-top: 25rem !important;
    }
    
}

@media (min-width:361px) and (max-width:375px) {
    .tutor_main{
        margin-top: 37rem !important;
    }
    
}

@media (min-width:376px) and (max-width:393px) {
    .tutor_main{
        margin-top: 26rem !important;
    }
}

@media (min-width:394px) and (max-width:414px) {
    .tutor_main{
        margin-top: 15rem !important;
    }
}

@media (min-width:415px) and (max-width:425px) {
    .tutor_main{
        margin-top: 39rem !important;
    }
}


@media (min-width:500px) and (max-width:540px) {
    .tutor_main{
        margin-top: 21rem !important;
    }
}

@media (min-width:500px) and (max-width:768px) {
    .tutor_main{
        margin-top: 8rem !important;
    }
} */

.modalBackground {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center; 
  z-index: 5 !important;
  bottom: 20%;
  right: 50px;
}

.modalContainer {
  width: 800px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 0 15px;

  
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
}

.modalContainer .body {
  flex: 50% 1;
  display: flex;
  justify-content: center;
  align-items: top;
  font-size: 15px;
  text-align: center;
  overflow-X: auto;

}

.modalContainer .footer {
  flex: 5% 1;
  display: flex;
  justify-content: center;
  align-items: center;
}



@media (max-width: 575.98px) { 
  .modalBackground {
 
    bottom: 0%;
    right: 7px;
  }
  .modalContainer {
    width: 100%;
    height: 700px;
    margin:0 10px;
   
  
    
  }
 }



.facebook:hover{
    color: blue;
    
}



.instagram:hover{

    color: rgb(212, 0, 255);
    
}



.twitter:hover{
    color: rgb(47, 186, 241);
}

@media screen and (max-width: 375px) {
    .makeStyles-navBarTextbtn-14{
        font-size: 10px !important;
    }
}

@media (min-width:376px) and (max-width: 800px) {
    .makeStyles-navBarTextbtn-14{
        font-size: 13px !important;
    }
}

@media (min-width:801px) and (max-width: 1200px) {
    .makeStyles-navBarTextbtn-14{
        font-size: 16px !important;
    }
}

@media screen and (max-width: 800px) {
    .MuiGrid-container{
        flex-wrap: nowrap !important;
    }

}

@media screen and (max-width:600px) {
    .res_margn{
        margin-right: 10px !important;
    }

    .jss14{
        font-size: 12px !important;
    }
}
.MuiDrawer-paperAnchorLeft{
    background-color: #96261D !important;
    /* border-right: 1px solid #fff; */
}
.root {
    display: flex;
    justify-content: space-around;
    /* background-color: red; */
}
.main-grid {
    display: flex;
    justify-content: space-evenly;
}
.icons {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
}
.icon-list {
    flex-direction: column;
}
.header {
    font-family: "Ink Free";

    color: #000;
    font-size: 45px;
}
.para {
    font-family: "Ink Free";

    color: #000;
    font-size: 20px;
}
li span {
    font-family: "Ink Free";

    color: #000;
    /* padding: 5px 8px; */
    /* margin-right: 5px; */
    font-size: 20px;
}
.img {
    display: flex;
    justify-content: center;
    padding-top: 50px 0px;
}
.main-div-style h1 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.main-div-style p {
    font-size: 25px;
    color: #fff;
} 
.popup {
    width: 380px;
    height: 400px;
}

@media screen and (max-width:800px) {
    .res_paddng{
        padding: 0px 20px !important;
        text-align: center !important;
    }
}
.root {
    display: flex;
    justify-content: space-around;
}
.main-grid {
    display: flex;
    justify-content: space-evenly;
}
.icons {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
}
.icon-list {
    flex-direction: column;
}
.icon_div{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.youtube_icon{
    color: rgb(228, 39, 39);
   
}
.website_icon{
    color: rgb(0, 0, 0);
    font-size: 100px;
}
@media only screen and (max-width: 600px) {
    .website_icon {
      margin-left: 20px;
    }
    .youtube_icon{
        margin-left: 20px;
    }
    .icon_div{
/* margin: 0 0 0 50px; */
width: 100%;
justify-content: center;
    }
  }
.header {
    font-family: "Ink Free";

    color: #000;
    font-size: 45px;
}
.para {
    font-family: "Ink Free";
    
    color: #000;
    font-size: 40px;
}
li span {
    font-family: "Ink Free";

    color: #000;
    /* padding: 5px 8px; */
    /* margin-right: 5px; */
    font-size: 20px;
}
.img {
    display: flex;
    justify-content: center;
    padding-top: 50px 0px;
    padding-bottom: 30px;
}
.main-div-style h1 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.main-div-style p {
    font-size: 25px;
    color: #fff;
} 
.popup {
    width: 380px;
    height: 400px;
}
.img_logo {
    width: 20px;
    height: 20px;
}
.root {
    display: flex;
    justify-content: space-around;
    /* background-color: red; */
}
.main-grid {
    display: flex;
    justify-content: space-evenly;
}
.icons {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
}
.icon-list {
    flex-direction: column;
}
.header {
        font-family: "New";

    color: #000;
    font-size: 45px;
}
.para {
font-family: "Ink Free";
    color: #000;
    font-size: 20px;
}
li span {
font-family: "Ink Free";
    color: #000;
    /* padding: 5px 8px; */
    /* margin-right: 5px; */
    font-size: 20px;
}
.img {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}
.main-div-style h1 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.main-div-style p {
    font-size: 25px;
    color: #fff;
}
.popup {
    width: 380px;
    height: 400px;
} 

@media screen and (max-width:800px) {
    .box_colmn_rev{
        flex-direction: column-reverse;
    }
    .box_colmn{
        flex-direction: column !important;
    }
}

.root {
    display: flex;
    justify-content: space-around;
    padding: 50px 0px;
    /* background-color: red; */
}
.main-grid {
    display: flex;
    justify-content: space-evenly;
}
.icons {
    display: flex;
    /* justify-content: space-between; */
    /* color: #000; */
    margin: 10px;
}
.icons span {
    color: #fff;
    padding: '0px 15px'
}
.icon-list {
    flex-direction: column;
}
.header {
   font-family: "Ink Free" ;
    letter-spacing: 3px;
    font-size: 2rem;
}
.para {
       font-family: "Ink Free";
    color: #fff;
    font-size: 50px;
    letter-spacing: 2px;
}
li span {
       font-family: "Ink Free";

    color: #fff;
    /* padding: 5px 8px; */
    /* margin-right: 5px; */
    font-size: 20px;
}
.img {
    display: flex;
    justify-content: center;

}
.main-div-style h1 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.main-div-style p {
    font-size: 25px;
    color: #fff;
} 
.popup {
    width: 380px;
    height: 400px;
}
.took {
    font-family: "Ink Free";
    
}


.newgrdain_icon{
    font-family: Ink Free;
    font-size: 2rem;
    font-weight: 900;

}
.icon {
    width: 3%;
    height: 3%;
    border: none;
    margin-top: 2px;
    margin-right: 16px;
  
}

.para_div {
display: flex;
justify-content: center;
}
.attributes_element {
    font-family: "Ink Free";
    font-size: 25px;
    margin: 20px 0px;
}

.vertical-timeline-element--work h3{
    /* border-radius: 50px; */
font-family: "Ink Free";
font-size: 1.6rem;
}
.vertical-timeline-element--work h1{
font-family: "Ink Free";
font-size: 35px;
font-weight: bold;

}
.roadmap-root {
    padding-bottom: 3rem;
}
.vertical-timeline-element-title span {
    font-size: 40px;
    padding-left: 5px;
}
.roadmap_para {
    text-align:  center;
    font-size: 30px;
    font-family: "Ink Free";

}
.video {
    width: 100% !important;
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;

}

@media screen and (max-width:600px) {
    #video {
        position: absolute;
        z-index: -1;
        width: auto;
        height: auto !important;
        top: 15%;
        max-height: 100%;
  max-width: 100%;
    }
    /* .makeStyles-grid-23 {
        height: 42vh !important;
        display: flex;
    } */

}

#video {

    position: absolute;
    z-index: -1;
    width: 100%;
  height: 100%;
  object-fit: cover;

}

@media screen and (max-width: 600px) {
   /* .makeStyles-grid-23{
    width: auto;
    height: 47vh !important;
   }
   .jss23{
    height: 60vh !important;
   } */

   body{
    width: auto !important;
   }

   #video{
    width: auto !important;
    height: auto !important;
   }
}

/* @media (min-width: 321px) and (max-width: 375px) {
    .makeStyles-grid-23{
     width: auto;
     height: 52vh !important;
    }
    .jss23{
     height: 60vh !important;
    }
 }

 @media (min-width: 376px) and (max-width: 600px) {
    .makeStyles-grid-23{
     width: auto;
     height: 57vh !important;
    }
    .jss23{
     height: 60vh !important;
    }
 } */
.secThree{
    background-color: #88301E;
    /* padding: 5% 0; */
font-family: "new-gardiner";;}
.secThreeHeader{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: rem;
}
.secThreeHeader h1{
    font-size: 3.5rem;
    /* font-family: "New Gardiner"; */
    font-family: "Ink Free";
    color: #fff;
    font-weight: 600;
    padding: 2.5rem 1rem 1.5rem 1rem;
}
.secThreeHeader p{
    font-size: 1.75rem;
    color: #fff;
    font-family: "Ink Free";
    /* text-transform: capitalize; */
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 50px; 
    margin: 50px; 
}
.secThreeTexts{
    display: flex;
    justify-content: center;
    font-family: "Ink Free";

}
.secThreeText{
    padding: 80px 0;
    background-image: url("/static/media/Surgical Theater Harvard Day.857923dd.jpg");
    /* width: 100%; */
    /* background-size: 100% 100%;  */
    background-attachment: fixed;
    background-position: 0 0;
    background-position: initial;
}
.boxes {
    justify-content: space-evenly;
}
.secThreeTextPara{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* display: flex; */
    /* justify-content: space-evenly; */
}


.secThreeTextParas{
 display: inline-block;
 margin: 15px 15px;
 background-color: #88301E;
 border: 1px solid gray;
 padding: 65px 60px;
 border-top-right-radius:50px ;
 border-bottom-left-radius:50px ;
}
.secThreeTextParas h3{
    font-size: 20px;
    text-align: left;
    font-weight: 700;
    color: #fff;
font-family: "Ink Free";

}
.secThreeTextParas h3 span{
    font-size: 3rem;
    color: #fff;
    padding: '0px 5px'

}
.secThreeTextParas p{
    display: inherit;
    font-family: Ink Free;

    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    width: 280px;
}
.secThreeTextParas-two{
    background-color: #317dba;
}

/*  */
   .secThreeTextParas2 h3{
       text-transform: uppercase;
       font-weight: 700;
       color: white;
       margin-bottom: 15px;
   
   }
   .secThreeTextParas2 h3 span{
       /* margin: 0 0px; */
       /* padding: 0 20px; */
       font-size: 3rem;
       color: white;
   }
   .mint {
       display: flex;
       justify-content: center;
   }
   
@media screen and (max-width:800px) {
    .box_colmn_sec{
        flex-direction: column;
    }
}



.main-div{
  overflow-x: hidden;
  background-color: #88301E;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
.main-div_2{
  overflow-x: hidden;
  background-color: #88301E;
  /* padding: 1rem 0 1rem 3rem; */
  padding-bottom: 3rem;
}
article {
  display: flex;
  width: 200% 100%;
  -webkit-animation: bannermove 20s linear infinite;
          animation: bannermove 20s linear infinite;
}
.article_two{
  display: flex;
  width: 200% 100%;
  animation: bannermoveTwo 20s reverse linear infinite;
}
article.paused {
  -webkit-animation-play-state: paused;
  animation-play-state: paused;
}z
div {
  width: 100%;
}
ul {
  display: flex;
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}
li {
  width: 100%;
  background: transparent;
  margin: 0 1rem;
}
img {
  width: 250px;
  height: 280px;
  /* border: 2px solid gray; */
  border-radius: 10px;
}
@-webkit-keyframes bannermove {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@keyframes bannermove {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
/* ----------------------------------- */
@-webkit-keyframes bannermoveTwo {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
@keyframes bannermoveTwo {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.mint_main {
    background-image: url(/static/media/Surgical.857923dd.jpg) !important;
    /* width: 100%; */
    background-size: cover;
    height: 110vh !important;
    /* width: 100vw !important; */
    padding: 0;
}

.mint_btnMain {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 350px;

}

.mint_btn {
    background-color: rgb(150, 38, 29);
    color: white;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    border: none;
}

.mint_btn:hover {
    background-color: rgb(112, 9, 1);
    color: white;
}



.modal_main {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    color: rgb(0, 0, 0) !important;
    text-transform: uppercase !important;
}

.jss4 {
    font-size: 34px;
    font-weight: 700 !important;
}

.jss5 {
    font-size: 20px;
    font-weight: 700;
    text-align: center !important;
}

.jss6 {
    padding: 0 !important;
}

.clr {
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
}

.increament_btn {
    padding: 9px 19px !important;
    border: none !important;
    outline: none !important;
    ;
    font-size: 22px !important;
    color: white;
}

.numbr_here {
    padding: 11px 15px !important;
    font-size: 22px !important;
    color: white;


}


.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.main_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jss7 {
    color: #fff;
    padding: 12px 21px 0px 21px;
    font-family: Integral Bold;
}

.MuiTypography-body1 {
    font-size: 23px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    letter-spacing: 0.00938em;
}

.jss9 {
    margin: 18px 0px !important;
}


@media screen and (max-width: 499px) {
    .modal-content {
        margin-top: 150px !important;
    }
    .mint_main{
        height: auto;
        padding-bottom: 3rem !important;
    }
}

@media (min-width:500px) and (max-width: 800px) {
    .modal-content {
        margin-top: 80px;
    }
}

.mint_para {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 190px !important;
    flex-direction: column !important;
}

.main_home_land_bg {
    padding: 80px 20px !important;
    /* height: 140vh!important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    text-align: center !important;
    margin-top: 70px;
}

:where(.css-diro6f).ant-app {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.main_home_heading1 {
    font-size: 46px;
    margin-top: 12px;
    font-weight: 700;
}

.home_land_para1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    padding-top: 6px;
}

.right_coonent {
    height: 500px !important;
}

.right_content_card {
    min-height: 500px;
    width: 320px;
    padding: 10px 0;
    margin: 1rem auto auto;
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150, 38, 29) !important;
    box-shadow: 0 0 10px #9ecaed;
}

@media (min-width: 700px) and (max-width: 800px) {
    .right_content_card {
        width: 300px;
    }
}

.card_heading_span,
.text_days {
    color: #fff !important;
    font-family: "Mina", sans-serif;
}

.card_heading_span {
    font-weight: 700;
    font-size: 25px !important;
    text-transform: capitalize !important;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px !important;
}

.text_days {
    color: #fff !important;
    font-family: "Mina", sans-serif;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px !important;
}

.progress_number {
    color: #06a84a !important;
    font-weight: 700 !important;
}

.lower_pro {
    background-color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 24px !important;
    height: 16px;
    margin: 12px 42px 0 !important;
}

.upper_pro {
    width: var(--width);
    background: #06a84a;
    border-radius: 10px;
    margin: 1px 0;
    transition: width .1s ease, background-color .1s ease;
    display: block;
    line-height: 1;
    position: relative;
}

.box_text {
    border: 1px solid #d0dbff;
    border-radius: 10px;
    padding: 16px;
    margin: 12px 42px 0 !important;
}

.crypto-btn {
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px solid #fff;
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
}

.crypto-btn img {
    border-right: 1px solid #fff;
    margin-right: 20px;
    padding-right: 6px;
    width: 60px !important;
    height: 60px !important;
    border-radius: 0px !important;
}

.mint_now_main {
    background-image: url(/static/media/Surgical.857923dd.jpg) !important;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
    padding: 0;
}

.button3 {
    background: rgb(150, 38, 29);
    box-shadow: 0 8px 6px -6px #555;
    transition: all .4s ease;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.button3:hover {
    background: rgb(150, 38, 29);
    letter-spacing: 2px;
    /* box-shadow: $no */
}

.crypto-btn span {
    color: #fff !important;
    font-family: "Mina", sans-serif;
    font-size: 16px !important;
    text-align: center;
    width: 70%;
    font-weight: 700;
    padding-right: 8px;
}

.new_btn {
    font-family: "Mina", sans-serif;
}

.new_btn {
    display: flex;
    justify-content: space-evenly;
}


.btn_center1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .btn_center1 {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

@media screen and (max-width:800px) {
    .ro_blck {
        display: block !important;
    }


}


.refferal_card {


    width: 350px;
    padding: 10px 20px;
    margin: 1rem auto auto;
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150, 38, 29) !important;
    box-shadow: 0 0 10px #9ecaed;
    height: auto;
    text-align: center;

}

.Refferal_input {
    background-color: transparent;
    border: 1px solid #fff;
    width: 250px;
    height: 2rem;
    color: #fff;
    font-size: 1.1rem;
}

.copy_refferal_address {
    /* display: flex;
    align-items: center; */
}

.copy_btn {

    padding: 5px 19px !important;
    border: none !important;
    outline: none !important;
    font-size: 22px !important;
    width: 250px;
    color: white;

}
.mint_main{
    /* width: 100%; */
    background-size: cover; 
    height: 110vh !important;
    /* width: 100vw !important; */
    padding: 0;
}

.mint_btnMain{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 350px;

}

.mint_btn{
    background-color: rgb(150,38,29);
    color: white;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    border: none;
}

.mint_btn:hover{
    background-color: rgb(112, 9, 1);
    color: white;
}



.modal_main{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    color: rgb(0, 0, 0) !important;
    text-transform: uppercase !important;
}

.jss4{
    font-size: 34px;
    font-weight: 700 !important;
}

.jss5{
    font-size: 20px;
    font-weight: 700;
    text-align: center !important;
}

.jss6 {
    padding: 0 !important;
}

.clr{
    background: linear-gradient(90deg,rgb(150,38,29),rgb(248, 101, 90));


}

.increament_btn{
    padding: 9px 19px !important;
    border: none !important;
    outline: none !important;;
    font-size: 22px !important;
    color:white;
}
.numbr_here{
    padding: 11px 15px !important;
    font-size: 22px !important;
    color:white;


}


.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.main_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jss7 {
    color: #fff;
    padding: 12px 21px 0px 21px;
    font-family: Integral Bold;
}

.MuiTypography-body1 {
    font-size: 23px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    letter-spacing: 0.00938em;
}

.jss9{
    margin: 18px 0px !important;
}


@media screen and (max-width: 499px) {
    .modal-content{
        margin-top: 150px !important;
    }
}

@media (min-width:500px) and (max-width: 800px) {
    .modal-content{
        margin-top: 80px;
    }
}

.mint_para{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 190px !important;
    flex-direction: column !important;
}

.main_home_land_bg {
    padding: 80px 20px!important;
    /* height: 140vh!important; */
    background-repeat: no-repeat!important;
    background-size: cover!important;
    text-align: center !important;
    margin-top: 70px;
}

:where(.css-diro6f).ant-app {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.main_home_heading1 {
    font-size: 46px;
    margin-top: 12px;
    font-weight: 700;
}

.home_land_para1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    padding-top: 6px;
}

.right_coonent {
    height: 500px!important;
}

.right_content_card {
    min-height: 500px;
    width: 320px;
    padding: 10px 0;
    margin: 1rem auto auto;
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150,38,29) !important;
    box-shadow: 0 0 10px #9ecaed;
}

@media (min-width: 700px) and (max-width: 800px) {
    .right_content_card{
        width: 300px;
    }
}

.card_heading_span, .text_days {
    color: #fff!important;
    font-family: "Mina",sans-serif;
}

.card_heading_span {
    font-weight: 700;
    font-size: 25px!important;
    text-transform: capitalize!important;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px!important;
}

.text_days {
    color: #fff!important;
    font-family: "Mina",sans-serif;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px!important;
}

.progress_number {
    color: #06a84a!important;
    font-weight: 700!important;
}

.lower_pro {
    background-color: #000!important;
    border: 1px solid #000!important;
    border-radius: 24px!important;
    height: 16px;
    margin: 12px 42px 0!important;
}

.upper_pro {
    width: var(--width);
    background: #06a84a;
    border-radius: 10px;
    margin: 1px 0;
    transition: width .1s ease,background-color .1s ease;
    display: block;
    line-height: 1;
    position: relative;
}

.box_text {
    border: 1px solid #d0dbff;
    border-radius: 10px;
    padding: 16px;
    margin: 12px 42px 0!important;
}

.crypto-btn {
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px solid #fff;
    background: linear-gradient(90deg,rgb(150,38,29),rgb(248, 101, 90));
}

.crypto-btn img {
    border-right: 1px solid #fff;
    margin-right: 20px;
    padding-right: 6px;
    width: 60px !important;
    height: 60px !important;
    border-radius: 0px !important;
}

.mint_now_main{
    background-size: cover; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
    padding: 0;
}

.button3{
    background: rgb(150,38,29);
    box-shadow: 0 8px 6px -6px #555;
    transition: all .4s ease; 
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.button3:hover{
    background: rgb(150,38,29);
    letter-spacing: 2px;
    /* box-shadow: $no */
}

.crypto-btn span {
    color: #fff!important;
    font-family: "Mina",sans-serif;
    font-size: 16px!important;
    text-align: center;
    width: 70%;
    font-weight: 700;
    padding-right: 8px;
}

.new_btn {
    font-family: "Mina",sans-serif;
}

.new_btn {
    display: flex;
    justify-content: space-evenly;
}


.btn_center1{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .btn_center1{
        margin-bottom: 20px;
        margin-top: 20px;
    }
}
.mint_main{
    /* width: 100%; */
    background-size: cover; 
    height: 110vh !important;
    /* width: 100vw !important; */
    padding: 0;
}

.mint_btnMain{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 350px;

}

.mint_btn{
    background-color: rgb(150,38,29);
    color: white;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    border: none;
}

.mint_btn:hover{
    background-color: rgb(112, 9, 1);
    color: white;
}



.modal_main{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    color: rgb(0, 0, 0) !important;
    text-transform: uppercase !important;
}

.jss4{
    font-size: 34px;
    font-weight: 700 !important;
}

.jss5{
    font-size: 20px;
    font-weight: 700;
    text-align: center !important;
}

.jss6 {
    padding: 0 !important;
}

.clr{
    background: linear-gradient(90deg,rgb(150,38,29),rgb(248, 101, 90));


}

.increament_btn{
    padding: 9px 19px !important;
    border: none !important;
    outline: none !important;;
    font-size: 22px !important;
    color:white;
}
.numbr_here{
    padding: 11px 15px !important;
    font-size: 22px !important;
    color:white;


}


.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.main_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jss7 {
    color: #fff;
    padding: 12px 21px 0px 21px;
    font-family: Integral Bold;
}

.MuiTypography-body1 {
    font-size: 23px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    letter-spacing: 0.00938em;
}

.jss9{
    margin: 18px 0px !important;
}


@media screen and (max-width: 499px) {
    .modal-content{
        margin-top: 150px !important;
    }
}

@media (min-width:500px) and (max-width: 800px) {
    .modal-content{
        margin-top: 80px;
    }
}

.mint_para{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 190px !important;
    flex-direction: column !important;
}

.main_home_land_bg {
    padding: 80px 20px!important;
    /* height: 140vh!important; */
    background-repeat: no-repeat!important;
    background-size: cover!important;
    text-align: center !important;
    margin-top: 70px;
    opacity: 1 !important;
}

:where(.css-diro6f).ant-app {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.main_home_heading1 {
    font-size: 46px;
    margin-top: 12px;
    font-weight: 700;
}

.home_land_para1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    padding-top: 6px;
}

.right_coonent {
    height: 500px!important;
}

.right_content_card {
    min-height: 500px;
    width: 320px;
    padding: 10px 0;
    margin: 1rem auto auto;
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150,38,29) !important;
    box-shadow: 0 0 10px #9ecaed;
}

@media (min-width: 700px) and (max-width: 800px) {
    .right_content_card{
        width: 300px;
    }
}

.card_heading_span, .text_days {
    color: #fff!important;
    font-family: "Mina",sans-serif;
}

.card_heading_span {
    font-weight: 700;
    font-size: 25px!important;
    text-transform: capitalize!important;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px!important;
}

.text_days {
    color: #fff!important;
    font-family: "Mina",sans-serif;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px!important;
}

.progress_number {
    color: #06a84a!important;
    font-weight: 700!important;
}

.lower_pro {
    background-color: #000!important;
    border: 1px solid #000!important;
    border-radius: 24px!important;
    height: 16px;
    margin: 12px 42px 0!important;
}

.upper_pro {
    width: var(--width);
    background: #06a84a;
    border-radius: 10px;
    margin: 1px 0;
    transition: width .1s ease,background-color .1s ease;
    display: block;
    line-height: 1;
    position: relative;
}

.box_text {
    border: 1px solid #d0dbff;
    border-radius: 10px;
    padding: 16px;
    margin: 12px 42px 0!important;
}

.crypto-btn {
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px solid #fff;
    background: linear-gradient(90deg,rgb(150,38,29),rgb(248, 101, 90));
}

.crypto-btn img {
    border-right: 1px solid #fff;
    margin-right: 20px;
    padding-right: 6px;
    width: 60px !important;
    height: 60px !important;
    border-radius: 0px !important;
}

.mint_now_main{
    background-size: cover; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
    padding: 0;
}

.button3{
    background: rgb(150,38,29);
    box-shadow: 0 8px 6px -6px #555;
    transition: all .4s ease; 
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.button3:hover{
    background: rgb(150,38,29);
    letter-spacing: 2px;
    /* box-shadow: $no */
}

.crypto-btn span {
    color: #fff!important;
    font-family: "Mina",sans-serif;
    font-size: 16px!important;
    text-align: center;
    width: 70%;
    font-weight: 700;
    padding-right: 8px;
}

.new_btn {
    font-family: "Mina",sans-serif;
}

.new_btn {
    display: flex;
    justify-content: space-evenly;
}


.btn_center1{
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .btn_center1{
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

@media screen and (max-width:320px) {
    .right_content_card {
        width: 250px;
    }

    .increament_btn {
        padding: 9px 14px !important;
    }

    .crypto-btn span{
        font-size: 13px !important;
    }

    .main_home_heading1{
        font-size: 38px;
    }

}

@media (min-width:321px) and (max-width:375px) {
    .main_home_heading1{
        font-size: 38px;
    }
}

@media screen and (max-width:800px) {
    .mint_main{
        height: 100% !important;
    }
}
.play_header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 50px;
}

.play_header_main {
  background-color: rgb(150, 38, 29);
  /* padding: 150px 0px; */
  font-family: Ink Free;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.mgin_fv{
  margin-left:60px;
}

.play_header p{
  font-size: 25px;
}

.play_header h1 {
  font-size: 55px;
}

.play_span {
  background-color: white;
  padding: 15px 20px;
  border-radius: 50%;
  color: rgb(150, 38, 29);
  font-size: 22px;
  width: 30px;
  height: 30px;
}

.play_span_2 {
  font-size: 22px;
}

.play_count_main {
  display: flex;
  justify-content: space-around !important;
}

.black_header {
  background: url(/static/media/GearBG-01.76ae1106.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: aquamarine;
}

.timer_text{
  display: flex;
    justify-content: center;
    align-items: center;
}

.page_circle {
  padding: 60px 70px;
  width: 30px;
  height: 30px;
  background-color: rgb(150, 38, 29);
  border-radius: 50%;
  float: right;
}

.black_header_text{
  font-family: 'OCR A Extended' !important;
}

.Timer_bck{
  background-color: rgb(150, 38, 29);
}


.black_header_text_p {
  font-size: 35px;
  font-weight: 900 !important;
  color: rgb(150, 38, 29);
}

.black_header_text_p2 {
  color: white;
  font-size: 23px;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  /* padding: 10px; */
  font-family: Ink Free;
}
.grid-item {
  padding: 20px;
  text-align: center;
  border: none;
}

.play_page_map img {
  height: 300vh;
  width: 100%;
  border-radius: 0px !important;
}

@media screen and (max-width: 600px) {
  .one_vala,.scnd_vala{
    margin-left: 0px !important;
  }
}

@media (min-width: 601px) and (max-width:800px) {
  .one_vala,.scnd_vala{
    margin-left: 110px !important;
  }
}

@media (min-width: 1000px) and (max-width:1200px) {
  .one_vala,.scnd_vala{
    margin-left: 150px !important;
  }
}

@media (min-width: 1201px) and (max-width:1370px) {
  .one_vala,.scnd_vala{
    margin-left: 240px !important;
  }
}

@media (min-width: 1370px) and (max-width:2560px) {
  .one_vala,.scnd_vala{
    margin-left: 330px !important;
  }
}
.one_vala,.scnd_vala{
  margin-left: 240px;
}

@media screen and (max-width: 700px) {
  .grid-item {
    grid-column-start: 1 !important;
    margin-top: 30px;
  }
  .page_circle {
    float: none;
    margin: 20px 0px;
    padding: 55px 70px;
  }

  .play_header {
    padding-top: 140px;
    text-align: center;
  }

  .one_vala {
    margin-bottom: 43px;
  }

  .chotha_vala {
    margin-top: 45px;
  }

  .play_span {
    font-size: 18px !important;
  }

  .play_span_2 {
    font-size: 16px;
  }

  .play_count {
    padding-left: 20px;
  }

  .black_header_text_p {
    font-size: 30px;
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .play_header {
    padding-top: 120px;
  }

  .mint_nft{
    font-size: 15px !important;
  }

  .play_span {
    font-size: 15px;
  }

  .play_span_2 {
    font-size: 15px;
  }
}

.main_bgg,.main_bg_res{
  background-image: url(/static/media/play_bg.b952c13c.jpg) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  /* height: 100% !important; */
}


.main_timelin {
  background-image: url(/static/media/line.2a8bf09a.png) !important;
  background-position: top center !important;
  background-repeat: no-repeat !important;
  background-size: cover  !important;
  z-index: 10000 !important;
  height: 131rem !important;
  padding-bottom: 120px !important;
  /* padding-top: 150px; */
}

.khali{
  padding-top: 70px !important;
}

.khali_2{
  padding-bottom: 140px !important;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
}
.grid-item {
  padding: 20px;
  font-size: 30px;
  text-align: center;
}

.time_box{
  border: 3px solid #818a91;
  background-color: #fcfeff !important;
  padding: 0px 15px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: bolder;
  color: rgb(150,38,29);
  margin-top: 20px;
  width: 70%;
  align-items: center;
}

/* .main_timelin{
  display: grid;
  grid-template-columns:
  repeat(auto-fit);
} */

.main_timelin img{
  width: 180px;
  height: 180px;
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px !important; */
  -webkit-filter: drop-shadow(0px 17px 0.75rem black);
          filter: drop-shadow(0px 17px 0.75rem black);
}

.main_timelin_res img{
  -webkit-filter: drop-shadow(0px 17px 0.75rem black);
          filter: drop-shadow(0px 17px 0.75rem black);
  width: 260px !important;
  height: 270px !important;
}

.thrd_item{
  grid-column-start: 2;
  grid-column-end: five;
  grid-row-start: row1-start;
  grid-row-end: 3;
}

.akhr_item,.scnd_item{
  align-self: end;
}

.last_itm{
  margin-top: 18rem;
  font-size: 30px;
}

.ssccnd{
  margin-top: -80px;
}

.thhrrd{
  margin-top: 290px;
  /* display: flex; */
  flex-direction: column;
}

.thhrrd_box{
  width: 45%;
}

.frrtth{
  margin-left: 350px;
  margin-top: 140px;
  font-size: 30px;
}

.ftth{
  margin-top: -50px;
}

.aatth{
  margin-top: 70px;
  font-size: 30px;
}

.nntth{
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  font-size: 30px;
}

.tnntth{
  margin-top: 40px;
  display: flex;
  /* flex-direction: column; */
  height: 10%;
  font-size: 30px;
  margin-left: 100px;
}

.emg_main{
  flex-direction: column;
}

.elvnnth{
  display: flex;
  flex-direction: column;
  height: 10%;
  padding-left: 190px;
  margin-top: 14rem;
  font-size: 30px;
}

.twllth{
  display: flex;
  flex-direction: column;
  height: 10%;
  padding-left: 190px;
}

.tnntth_box{
  width: 100%;
  margin-right: 10px;
}

.twllth_box{
  width: 45%;
}

.elvnnth_box{
  width: 75%;
}

.frrtth_box{
  width: 73%;
}

.chhheva_box{
  width: 57%;
}

.svvntth_box{
  width: 60%;
}

.aatth_box{
  width: 60%;
}

.pehhla_box{
  width: 58%;
}

.thhrrd_box{
  width: 47%;
}

.nntth_box{
  width:  60%;
}



@media screen and (max-width:550px) {
  .time_box{
    width: 80%;
    font-size: 25px;
  }
}

@media (min-width:1001px) and (max-width:1100px) {
  .main_timelin img{
    width: 150px;
    height: 150px;
  }

  .pehhla_box{
    width: 73%;
  }

  .ssccnd_box{
    width: 93%;
  }

  .thhrrd_box {
    width: 55%;
}

  .ftth_box,.chhheva_box,.svvntth_box{
    width: 86%;
  }

  .frrtth_box {
    width: 100%;
}

  .aatth_box {
    width: 76%;
}

.nntth_box {
  width: 78%;
}

  .aatth {
    margin-top: 231px;
}

.nntth{
  margin-top: 4rem;
}

.frrtth {
  margin-left: 190px;
  /* margin-top: 300px; */
}

.elvnnth{
  margin-top: 12rem;
  }

  .last_itm {
    margin-top: 14rem;
  }

  .elvnnth_box{
    width: 100%;
  }

  .twllth_box {
    width: 54%;
}

  .tnntth_box {
    width: 100%;
}

  .grid-item{
    font-size: 25px;
  }

  .frrtth,.aatth,.nntth,.tnntth,.elvnnth,.twllth{
    font-size: 25px;
  }
}

@media (min-width:901px) and (max-width:1000px) {
  .main_timelin{
    flex-direction: column !important;
    background-size: contain !important;
    height: -webkit-max-content !important;
    height: max-content !important;
  }

  .one_vala,.scnd_vala{
    margin-left: 110px !important;
  }

  .mint_nft{
    font-size: 15px !important;
  }

  .play_span {
    font-size: 15px;
  }

  .play_span_2 {
    font-size: 15px;
  }

  .grid-item{
    font-size: 20px;
  }

  .frrtth,.aatth,.nntth,.tnntth,.elvnnth,.twllth{
    font-size: 20px;
  }

  .main_timelin img{
    width: 120px !important;
    height: 120px !important;
  }

  .khali_2{
    padding-bottom: 0 !important;
  }

  .ftth{
    margin-top: -170px;
  }

  .ftth_box{
    width: 100%;
    font-size: 14px;
  }

  .pehhla_box{
    width: 100%;
    font-size: 13px;
  }

  .ssccnd_box{
    width: 100%;
    font-size: 14px;
  }

  .thhrrd_box {
    width: 50%;
    font-size: 14px;
}

.thhrrd {
  margin-top: 224px;
}

.frrtth{
  margin-left: 100px;
}

  .frrtth_box{
    width: 100%;
    font-size: 13px;
  }

  .chhheva_box,.svvntth_box{
    width: 100%;
    font-size: 13px;
  }

  .aatth_box{
    width: 80%;
    font-size: 14px;
  }

  .nntth_box{
    width: 80%;
    font-size: 14px;
  }

  .tnntth_box {
    width: 100%;
    font-size: 14px;
}

  .elvnnth_box{
    width: 100%;
    font-size: 13px;
  }

  .twllth_box{
    width: 50%;
    font-size: 14px;
  }

  .chhheva{
    margin-top: -130px;
  }

  .svvntth{
    margin-top: -140px;
  }

  .aatth{
    margin-top: -60px;
  }

  .nntth {
    margin-top: 52px;
  }

  .tnntth, .elvnnth{
    font-size: 24px;
  }

  .elvnnth{
    margin-top: 120px;
    padding-left: 10px;
  }

  .twllth{
    margin-top: 160px;
    padding-left: 10px;
  }

  .elvnnth,.twllth,.tnntth {
    /* padding-left: 20px; */
    height: 20%;
  }


}

@media (min-width:801px) and (max-width:900px) {

  .one_vala,.scnd_vala{
    margin-left: 110px !important;
  }

  .grid-item{
    font-size: 5px !important;
  }

  .mint_nft{
    font-size: 15px !important;
  }

  .play_span {
    font-size: 15px;
  }

  .play_span_2 {
    font-size: 15px;
  }

  .frrtth {
    margin-left: 33px;
    margin-top: 200px;
}

.frrtth_box {
  width: 105%;
}

.chhheva_box{
  width: 80%;
}

.chhheva{
  margin-left: -70px;
}

.svvntth{
  margin-top: 80px;
}

.ftth{
  margin-top: 10px;
}

.chhheva_box{
  margin-top: -180px;
}

.tnntth_box{
  width: 115%;
}

.aatth {
  margin-top: -80px;
}

.last_itm {
  margin-top: 12rem;
  padding-left: 40px;
}

.nntth {
  margin-top: 76px;
}

.elvnnth{
  margin-top: 174px;
  height: 24%;
}

.twllth,.tnntth{
  height: 25% !important;
}

.main_timelin img {
  width: 120px !important;
  height: 120px !important;
  /* box-shadow: 10px 10px 5px #aaaaaa; */
  /* box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px; */
}

.pehhla_box{
  width: 80%;
}

.thhrrd_box {
  width: 115%;
}

.ssccnd {
  margin-top: 10px;
}

.ssccnd_box,.ftth_box{
  width: 103%;
}

.svvntth_box{
  width: 80%;
}

.aatth_box{
  width: 82%;
}

.nntth_box{
  width: 85%;
}

.elvnnth_box{
  width: 115%;
}

.twllth {
  display: flex;
  height: 10%;
  padding-left: 30px;
}

.twllth_box {
  width: 110%;
}

.main_timelin{
background-size: cover !important;
height: 105rem !important;
}
}

@media (min-width:501px) and (max-width:800px) {
  .main_timelin{
    height: 140rem !important;
    display: flex !important;
    flex-direction: column !important;
    background-size: contain !important;
    height: -webkit-max-content !important;
    height: max-content !important;
  }

  .main_timelin img{
    width: 100px !important;
    height: 100px !important;
  }

  .grid-item{
    font-size: 20px;
  }

  .frrtth,.aatth,.nntth,.tnntth,.elvnnth,.twllth{
    font-size: 20px;
  }

  .time_box{
    border: 3px solid #818a91;
    background-color: #fcfeff !important;
    padding: 0px 15px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: bolder;
    color: rgb(150,38,29);
    width: 50%;
    align-items: center;
  }
  .khali_2{
    padding-bottom: 0 !important;
  }

  .frrtth{
    margin-left: 112px;
  }

  .ftth{
    margin-top: -200px;
  }

  .ftth{
    margin-top: -72px;
  }

  .pehhla_box{
    width: 67%;
  }

  .ssccnd_box,.ftth_box{
    width: 100%;
  }

  .frrtth_box{
    width: 105%;
  }

  .chhheva_box,.svvntth_box{
    width: 75%;
  }

  .aatth {
    margin-top: -40px;
  }

  .pehhla_box {
    width: 75%;
}

  .aatth_box{
    width: 75%;
  }

  .nntth_box{
    width: 75%;
  }

  .thhrrd_box{
    width: 50%;
  }

  .tnntth_box{
    width: 100%;
    height: 60%;
  }

  .elvnnth_box{
    width: 110%;
  }

  .twllth_box{
    width: 55%;
  }

  .twllth{
    width: 94%;
    padding-left: 110px;
  }

  .ssccnd {
    margin-top: -15px;
}

  .tnntth {
    height: 15%;
  }

  .elvnnth{
    margin-top: 11rem;
    height: 15%;
    padding-left: 167px;
  }

  .thhrrd {
    margin-top: 206px;
  }

  .last_itm {
    margin-top: 12rem;
 }
}

@media screen and (max-width:500px) {

  .main_timelin{
    height: auto !important;
    background-image: none !important;
    display: none !important;
  }
  .grid-container{
    display: flex !important;
    flex-direction: column !important;
  }

  .grid-container{
    font-size: 20px !important;
  }
}

@media screen and (max-width:550px) {
  .main_bgg{
    display: none !important;
  }
}

@media (min-width:551px) and (max-width:5000px) {
  .main_bg_res{
    display: none !important;
  }
}

@media (min-width:1440px) and (max-width: 4000px) {
  .main_timelin{
    height: 154rem !important;
  }

  .frrtth_box {
    width: 60%;
}

  .elvnnth{
    margin-top: 29rem;
    padding-left: 0px;
    height: 5%;
  }

  .elvnnth_box {
    width: 62%;
}

  .svvntth_box{
    display: flex;
    flex-direction: column;
  }

  .aatth {
    margin-left: -25rem;
    font-size: 30px;
    margin-top: 40px;
}

.aatth_box {
  width: 25%;
}

.aatth_emg{
  display: flex;
  flex-direction: column;
}

  .twllth{
    height: 5%;
  }

  .tnntth{
    margin-left: 100px;
    height: 5%;
  }

  .last_itm {
    margin-top: 39rem;
}
}

@media screen and (max-width:300px) {
  .main_timelin_res img {
    -webkit-filter: drop-shadow(0px 17px 0.75rem black);
            filter: drop-shadow(0px 17px 0.75rem black);
    width: 208px !important;
    height: 221px !important;
}
}

@media screen and (max-width:600px) {
  .main_vala{
    display: none !important;
  }
}

@media (min-width:601px) and (max-width: 9000px) {
  .responc{
    display: none !important;
  }
}

.main_bgg img{
  border-radius: 0px !important;
}

.main_bg_res img{
  border-radius: 0px !important;
}


.contBtn{
    background-color: #fff;
    padding: 10px 25px !important;
    border-radius: 20px;
    font-family: "Ink Free";
    font-size: 20px;
    cursor: pointer;
    color: #000 !important;
    font-weight: 700 !important;
}
