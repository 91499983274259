.secThree{
    background-color: #88301E;
    /* padding: 5% 0; */
font-family: "new-gardiner";;}
.secThreeHeader{
    display: flex;
    justify-content: center;
    text-align: center;
    padding-top: rem;
}
.secThreeHeader h1{
    font-size: 3.5rem;
    /* font-family: "New Gardiner"; */
    font-family: "Ink Free";
    color: #fff;
    font-weight: 600;
    padding: 2.5rem 1rem 1.5rem 1rem;
}
.secThreeHeader p{
    font-size: 1.75rem;
    color: #fff;
    font-family: "Ink Free";
    /* text-transform: capitalize; */
    letter-spacing: normal;
    text-align: center;
    padding-bottom: 50px; 
    margin: 50px; 
}
.secThreeTexts{
    display: flex;
    justify-content: center;
    font-family: "Ink Free";

}
.secThreeText{
    padding: 80px 0;
    background-image: url("./Surgical Theater Harvard Day.jpg");
    /* width: 100%; */
    /* background-size: 100% 100%;  */
    background-attachment: fixed;
    background-position: initial;
}
.boxes {
    justify-content: space-evenly;
}
.secThreeTextPara{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    /* display: flex; */
    /* justify-content: space-evenly; */
}


.secThreeTextParas{
 display: inline-block;
 margin: 15px 15px;
 background-color: #88301E;
 border: 1px solid gray;
 padding: 65px 60px;
 border-top-right-radius:50px ;
 border-bottom-left-radius:50px ;
}
.secThreeTextParas h3{
    font-size: 20px;
    text-align: left;
    font-weight: 700;
    color: #fff;
font-family: "Ink Free";

}
.secThreeTextParas h3 span{
    font-size: 3rem;
    color: #fff;
    padding: '0px 5px'

}
.secThreeTextParas p{
    display: inherit;
    font-family: Ink Free;

    color: #fff;
    font-weight: 500;
    font-size: 1.4rem;
    width: 280px;
}
.secThreeTextParas-two{
    background-color: #317dba;
}

/*  */
   .secThreeTextParas2 h3{
       text-transform: uppercase;
       font-weight: 700;
       color: white;
       margin-bottom: 15px;
   
   }
   .secThreeTextParas2 h3 span{
       /* margin: 0 0px; */
       /* padding: 0 20px; */
       font-size: 3rem;
       color: white;
   }
   .mint {
       display: flex;
       justify-content: center;
   }
   
@media screen and (max-width:800px) {
    .box_colmn_sec{
        flex-direction: column;
    }
}

