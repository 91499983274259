.mint_main {
    background-image: url('../../assets/Surgical.jpg') !important;
    /* width: 100%; */
    background-size: cover;
    height: 110vh !important;
    /* width: 100vw !important; */
    padding: 0;
}

.mint_btnMain {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 350px;

}

.mint_btn {
    background-color: rgb(150, 38, 29);
    color: white;
    padding: 15px 30px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 16px;
    border: none;
}

.mint_btn:hover {
    background-color: rgb(112, 9, 1);
    color: white;
}



.modal_main {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    color: rgb(0, 0, 0) !important;
    text-transform: uppercase !important;
}

.jss4 {
    font-size: 34px;
    font-weight: 700 !important;
}

.jss5 {
    font-size: 20px;
    font-weight: 700;
    text-align: center !important;
}

.jss6 {
    padding: 0 !important;
}

.clr {
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
}

.increament_btn {
    padding: 9px 19px !important;
    border: none !important;
    outline: none !important;
    ;
    font-size: 22px !important;
    color: white;
}

.numbr_here {
    padding: 11px 15px !important;
    font-size: 22px !important;
    color: white;


}


.MuiButton-label {
    width: 100%;
    display: inherit;
    align-items: inherit;
    justify-content: inherit;
}

.main_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.jss7 {
    color: #fff;
    padding: 12px 21px 0px 21px;
    font-family: Integral Bold;
}

.MuiTypography-body1 {
    font-size: 23px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 600;
    letter-spacing: 0.00938em;
}

.jss9 {
    margin: 18px 0px !important;
}


@media screen and (max-width: 499px) {
    .modal-content {
        margin-top: 150px !important;
    }
    .mint_main{
        height: auto;
        padding-bottom: 3rem !important;
    }
}

@media (min-width:500px) and (max-width: 800px) {
    .modal-content {
        margin-top: 80px;
    }
}

.mint_para {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-top: 190px !important;
    flex-direction: column !important;
}

.main_home_land_bg {
    padding: 80px 20px !important;
    /* height: 140vh!important; */
    background-repeat: no-repeat !important;
    background-size: cover !important;
    text-align: center !important;
    margin-top: 70px;
}

:where(.css-diro6f).ant-app {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

.main_home_heading1 {
    font-size: 46px;
    margin-top: 12px;
    font-weight: 700;
}

.home_land_para1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    padding-top: 6px;
}

.right_coonent {
    height: 500px !important;
}

.right_content_card {
    min-height: 500px;
    width: 320px;
    padding: 10px 0;
    margin: 1rem auto auto;
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150, 38, 29) !important;
    box-shadow: 0 0 10px #9ecaed;
}

@media (min-width: 700px) and (max-width: 800px) {
    .right_content_card {
        width: 300px;
    }
}

.card_heading_span,
.text_days {
    color: #fff !important;
    font-family: "Mina", sans-serif;
}

.card_heading_span {
    font-weight: 700;
    font-size: 25px !important;
    text-transform: capitalize !important;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px !important;
}

.text_days {
    color: #fff !important;
    font-family: "Mina", sans-serif;
}

.text_days {
    margin-bottom: 0;
    line-height: 30px !important;
}

.progress_number {
    color: #06a84a !important;
    font-weight: 700 !important;
}

.lower_pro {
    background-color: #000 !important;
    border: 1px solid #000 !important;
    border-radius: 24px !important;
    height: 16px;
    margin: 12px 42px 0 !important;
}

.upper_pro {
    width: var(--width);
    background: #06a84a;
    border-radius: 10px;
    margin: 1px 0;
    transition: width .1s ease, background-color .1s ease;
    display: block;
    line-height: 1;
    position: relative;
}

.box_text {
    border: 1px solid #d0dbff;
    border-radius: 10px;
    padding: 16px;
    margin: 12px 42px 0 !important;
}

.crypto-btn {
    display: flex;
    align-items: center;
    border-radius: 10px;
    text-transform: uppercase;
    border: 2px solid #fff;
    background: linear-gradient(90deg, rgb(150, 38, 29), rgb(248, 101, 90));
}

.crypto-btn img {
    border-right: 1px solid #fff;
    margin-right: 20px;
    padding-right: 6px;
    width: 60px !important;
    height: 60px !important;
    border-radius: 0px !important;
}

.mint_now_main {
    background-image: url('../../assets//Surgical.jpg') !important;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh !important;
    padding: 0;
}

.button3 {
    background: rgb(150, 38, 29);
    box-shadow: 0 8px 6px -6px #555;
    transition: all .4s ease;
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.button3:hover {
    background: rgb(150, 38, 29);
    letter-spacing: 2px;
    /* box-shadow: $no */
}

.crypto-btn span {
    color: #fff !important;
    font-family: "Mina", sans-serif;
    font-size: 16px !important;
    text-align: center;
    width: 70%;
    font-weight: 700;
    padding-right: 8px;
}

.new_btn {
    font-family: "Mina", sans-serif;
}

.new_btn {
    display: flex;
    justify-content: space-evenly;
}


.btn_center1 {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 500px) {
    .btn_center1 {
        margin-bottom: 20px;
        margin-top: 20px;
    }
}

@media screen and (max-width:800px) {
    .ro_blck {
        display: block !important;
    }


}


.refferal_card {


    width: 350px;
    padding: 10px 20px;
    margin: 1rem auto auto;
    background-color: rgba(1, 1, 26, 0.466);
    border-radius: 1rem;
    border-top: 3px;
    border-style: solid;
    border-color: rgb(150, 38, 29) !important;
    box-shadow: 0 0 10px #9ecaed;
    height: auto;
    text-align: center;

}

.Refferal_input {
    background-color: transparent;
    border: 1px solid #fff;
    width: 250px;
    height: 2rem;
    color: #fff;
    font-size: 1.1rem;
}

.copy_refferal_address {
    /* display: flex;
    align-items: center; */
}

.copy_btn {

    padding: 5px 19px !important;
    border: none !important;
    outline: none !important;
    font-size: 22px !important;
    width: 250px;
    color: white;

}