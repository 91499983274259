.modalBackground {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center; 
  z-index: 5 !important;
  bottom: 20%;
  right: 50px;
}

.modalContainer {
  width: 800px;
  height: 500px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 0 15px;

  
}

.modalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  font-weight: bold;
}

.modalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: top;
  font-size: 15px;
  text-align: center;
  overflow-X: auto;

}

.modalContainer .footer {
  flex: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}



@media (max-width: 575.98px) { 
  .modalBackground {
 
    bottom: 0%;
    right: 7px;
  }
  .modalContainer {
    width: 100%;
    height: 700px;
    margin:0 10px;
   
  
    
  }
 }
