.video {
    width: 100% !important;
    transform: scale(1) !important;

}

@media screen and (max-width:600px) {
    #video {
        position: absolute;
        z-index: -1;
        width: auto;
        height: auto !important;
        top: 15%;
        max-height: 100%;
  max-width: 100%;
    }
    /* .makeStyles-grid-23 {
        height: 42vh !important;
        display: flex;
    } */

}

#video {

    position: absolute;
    z-index: -1;
    width: 100%;
  height: 100%;
  object-fit: cover;

}

@media screen and (max-width: 600px) {
   /* .makeStyles-grid-23{
    width: auto;
    height: 47vh !important;
   }
   .jss23{
    height: 60vh !important;
   } */

   body{
    width: auto !important;
   }

   #video{
    width: auto !important;
    height: auto !important;
   }
}

/* @media (min-width: 321px) and (max-width: 375px) {
    .makeStyles-grid-23{
     width: auto;
     height: 52vh !important;
    }
    .jss23{
     height: 60vh !important;
    }
 }

 @media (min-width: 376px) and (max-width: 600px) {
    .makeStyles-grid-23{
     width: auto;
     height: 57vh !important;
    }
    .jss23{
     height: 60vh !important;
    }
 } */