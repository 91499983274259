.root {
    display: flex;
    justify-content: space-around;
}
.main-grid {
    display: flex;
    justify-content: space-evenly;
}
.icons {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
}
.icon-list {
    flex-direction: column;
}
.icon_div{
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.youtube_icon{
    color: rgb(228, 39, 39);
   
}
.website_icon{
    color: rgb(0, 0, 0);
    font-size: 100px;
}
@media only screen and (max-width: 600px) {
    .website_icon {
      margin-left: 20px;
    }
    .youtube_icon{
        margin-left: 20px;
    }
    .icon_div{
/* margin: 0 0 0 50px; */
width: 100%;
justify-content: center;
    }
  }
.header {
    font-family: "Ink Free";

    color: #000;
    font-size: 45px;
}
.para {
    font-family: "Ink Free";
    
    color: #000;
    font-size: 40px;
}
li span {
    font-family: "Ink Free";

    color: #000;
    /* padding: 5px 8px; */
    /* margin-right: 5px; */
    font-size: 20px;
}
.img {
    display: flex;
    justify-content: center;
    padding-top: 50px 0px;
    padding-bottom: 30px;
}
.main-div-style h1 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.main-div-style p {
    font-size: 25px;
    color: #fff;
} 
.popup {
    width: 380px;
    height: 400px;
}
.img_logo {
    width: 20px;
    height: 20px;
}