

.facebook:hover{
    color: blue;
    
}



.instagram:hover{

    color: rgb(212, 0, 255);
    
}



.twitter:hover{
    color: rgb(47, 186, 241);
}

@media screen and (max-width: 375px) {
    .makeStyles-navBarTextbtn-14{
        font-size: 10px !important;
    }
}

@media (min-width:376px) and (max-width: 800px) {
    .makeStyles-navBarTextbtn-14{
        font-size: 13px !important;
    }
}

@media (min-width:801px) and (max-width: 1200px) {
    .makeStyles-navBarTextbtn-14{
        font-size: 16px !important;
    }
}

@media screen and (max-width: 800px) {
    .MuiGrid-container{
        flex-wrap: nowrap !important;
    }

}

@media screen and (max-width:600px) {
    .res_margn{
        margin-right: 10px !important;
    }

    .jss14{
        font-size: 12px !important;
    }
}