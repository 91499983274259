.root {
    display: flex;
    justify-content: space-around;
    /* background-color: red; */
}
.main-grid {
    display: flex;
    justify-content: space-evenly;
}
.icons {
    display: flex;
    /* justify-content: space-between; */
    margin: 10px;
}
.icon-list {
    flex-direction: column;
}
.header {
    font-family: "Ink Free";

    color: #000;
    font-size: 45px;
}
.para {
    font-family: "Ink Free";

    color: #000;
    font-size: 20px;
}
li span {
    font-family: "Ink Free";

    color: #000;
    /* padding: 5px 8px; */
    /* margin-right: 5px; */
    font-size: 20px;
}
.img {
    display: flex;
    justify-content: center;
    padding-top: 50px 0px;
}
.main-div-style h1 {
    font-size: 40px;
    color: #fff;
    font-weight: bold;
}
.main-div-style p {
    font-size: 25px;
    color: #fff;
} 
.popup {
    width: 380px;
    height: 400px;
}

@media screen and (max-width:800px) {
    .res_paddng{
        padding: 0px 20px !important;
        text-align: center !important;
    }
}